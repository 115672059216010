import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";
import { postBlwDisposeReportRequest } from "@/service/api/serviceApi";
import { sendErrorLogToSentry } from "@/utils/utils";

export const useRequestStore = defineStore({
  id: "request",
  state: () => ({
    govId: useSessionStorage("govId", null),
    disposeDate: useSessionStorage("disposeDate", null),
    address: useSessionStorage("address", null),
    addressDetail: useSessionStorage("addressDetail", null),
    addressMemo: useSessionStorage("addressMemo", null),
    disposeLocationId: useSessionStorage("disposeLocationId", null),
    registeredLocationId: useSessionStorage("registeredLocationId", null),
    addressType: useSessionStorage("addressType", null),
    postCodeJson: useSessionStorage("postCodeJson", null),
    paymentMethodId: useSessionStorage("paymentMethodId", null),
    paymentAccountId: useSessionStorage("paymentAccountId", null), // /payemnt api 헤더에 사용하는 값
    paymentAccountSecretKey: useSessionStorage("paymentAccountSecretKey", null), // /payemnt api 헤더에 사용하는 값
    paymentCashReceiptNumber: useSessionStorage(
      "paymentCashReceiptNumber",
      null
    ), // 계좌이체일 경우 사용하는 값으로 0: 개인소득공제, 1: 사업자지출증빙,
    /*
    bankInfo data set
    {
      bankDisplay: "대구은행"
      accountNumber: "508140843236",
      accountHolder: "김원빈"      
    }
    */
    bankInfo: useSessionStorage("bankInfo", {
      bankDisplay: null,
      accountNumber: null,
      accountHolder: null,
    }),
    name: useSessionStorage("name", null),
    cellphone: useSessionStorage("cellphone", null),
    disposeMemo: useSessionStorage("disposeMemo", null),
    /* itemList 타입
    {
      id	:	905
      name	:	모든 규격
      categoryName	:	아동용 자전거
      price	:	2000
      quantity	:	1
      isSelected	:	true
    }
    */
    itemList: useSessionStorage("itemList", []),
    imageList: useSessionStorage("imageList", []),
    /* blw_erp_admin_web 의 keyData.ts -> requestChannel 참고    
      {
        id: 'zg_web',
        label: '지구하다 웹'
      },
      {
        id: 'zg_app',
        label: '지구하다 앱'
      },      
    */
    channel: useSessionStorage("channel", "zg_web"), // 배출신고 어디서 햇는지 체크용
  }),
  actions: {
    setDisposeDate(disposeDate) {
      this.disposeDate = disposeDate;
    },
    setAddressField(address, postCodeJson, addressType) {
      this.address = address;
      this.postCodeJson = postCodeJson;
      this.addressType = addressType;
    },
    setDisposeField(
      address,
      addressDetail,
      addressMemo,
      postCodeJson,
      disposeLocationId,
      registeredLocationId,
      addressType,
      disposeMemo,
      name,
      cellphone
    ) {
      this.setAddressField(address, postCodeJson, addressType);
      this.addressDetail = addressDetail;
      this.addressMemo = addressMemo;
      this.disposeLocationId = disposeLocationId;
      this.registeredLocationId = registeredLocationId;
      this.disposeMemo = disposeMemo;
      this.name = name;
      this.cellphone = cellphone;
    },
    setPaymentData(
      paymentMethodId,
      bankDisplay,
      accountNumber,
      accountHolder,
      paymentAccountId,
      paymentAccountSecretKey
    ) {
      this.paymentMethodId = paymentMethodId;
      this.bankInfo = {
        bankDisplay: bankDisplay,
        accountHolder: accountHolder,
        accountNumber: accountNumber,
      };
      this.paymentAccountId = paymentAccountId;
      this.paymentAccountSecretKey = paymentAccountSecretKey;
    },
    addItemList(inputItemList) {
      inputItemList.forEach((element) => {
        const existingItemIndex = this.itemList.findIndex(
          (item) => item.id === element.id
        );

        if (existingItemIndex !== -1) {
          this.itemList[existingItemIndex].quantity += element.quantity;
        } else {
          this.itemList.push(element);
        }
      });
    },
    isContainItemList(item) {
      const isIncludes = this.itemList.some(
        (element) => element.id === item.id
      );

      return isIncludes;
    },
    removeItemFormList(targetItemId) {
      if (confirm("해당 품목을 삭제하겠습니까?")) {
        const idx = this.itemList.findIndex((item) => {
          return item.id === targetItemId;
        });
        if (idx > -1) {
          this.itemList.splice(idx, 1);
        }
      }
    },
    changeQuantityButton(isPlus, itemId) {
      this.itemList.forEach((element) => {
        if (element.id === itemId) {
          if (isPlus) {
            element.quantity++;
          } else {
            if (element.quantity > 1) {
              element.quantity--;
            }
          }
        }
      });
    },
    getTotalPrice() {
      let quantity = 0;

      this.itemList.forEach((element) => {
        quantity += element.quantity * element.price;
      });

      return quantity;
    },
    getNavigatorItemTitle() {
      if (this.itemList.length === 0) {
        return "";
      } else if (this.itemList.length > 1) {
        const firstItem = this.itemList[0].categoryName;

        return firstItem + " 외 " + (this.itemList.length - 1) + "건";
      } else {
        const firstItem = this.itemList[0].categoryName;

        return firstItem;
      }
    },
    resetAllData() {
      this.govId = null;
      this.disposeDate = null;
      this.address = null;
      this.addressDetail = null;
      this.addressMemo = null;
      this.disposeLocationId = null;
      this.registeredLocationId = null;
      this.addressType = null;
      this.paymentMethodId = null;
      this.disposeMemo = null;
      this.name = null;
      this.cellphone = null;
      this.itemList = [];
      this.bankInfo = {
        bankDisplay: null,
        accountNumber: null,
        accountHolder: null,
      };
      this.paymentAccountId = null;
      this.paymentAccountSecretKey = null;
    },
    canCardPayment() {
      return (
        this.paymentAccountId != null &&
        this.paymentAccountSecretKey != null &&
        this.paymentMethodId
      );
    },
    canBankPayment() {
      return (
        this.bankInfo != null &&
        this.bankInfo.accountNumber != null &&
        this.bankInfo.accountHolder != null &&
        this.bankInfo.bankDisplay != null
      );
    },
  },
});
