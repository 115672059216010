<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ExclamationTriangleIcon,
  CheckIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/outline";
import { useLayoutStore } from "@/stores/layoutStore.js";
import { Severities } from "@/utils/constant";

const layoutStore = useLayoutStore();

const cancel = () => {
  layoutStore.closeConfirm();
};

const accept = async () => {
  await layoutStore.confirmAccept();
};
const reject = async () => {
  await layoutStore.confirmReject();
};

const detectAnimationLeave = () => {
  layoutStore.clearConfirm();
};
</script>

<template>
  <Teleport to="body">
    <TransitionRoot :show="layoutStore.showConfirm === true" as="template">
      <Dialog
        class="relative z-[999]"
        :open="layoutStore.showConfirm === true"
        @close="cancel">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div
          class="fixed inset-0 z-[999] flex items-center justify-center flex-wrap self-center">
          <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            @leave="detectAnimationLeave">
            <img
              v-if="layoutStore.confirm?.showLogo"
              class="transform transition-all"
              src="@/assets/icons/icon_zgu.svg" />
            <DialogPanel
              class="transform overflow-hidden flex flex-col rounded-lg bg-white text-left shadow-xl transition-all mx-[16px]"
              style="
                min-width: 320px;
                width: calc(100% - 32px);
                max-width: 800px;
                max-height: 500px;
                border-radius: 16px;
              ">
              <div
                class="flex-grow bg-white"
                style="border-bottom: 1px solid rgba(204, 213, 226, 0.16)">
                <div class="sm:flex sm:items-start">
                  <div class="w-full flex flex-col text-center align-center">
                    <!-- title -->
                    <div
                      class="flex justify-center items-center h-[56px] mx-[24px] mt-[24px]">
                      <DialogTitle
                        as="span"
                        class="text-center text-base subtitle_8">
                        <template
                          v-if="Array.isArray(layoutStore.confirm?.summary)">
                          <p
                            v-for="(summary, index) in layoutStore.confirm?.summary"
                            :key="index"
                            class="subtitle_8">
                            {{ summary }}
                          </p>
                        </template>
                        <template v-else
                          ><p class="subtitle_8">
                            {{ layoutStore.confirm?.summary }}
                          </p></template
                        >
                      </DialogTitle>
                    </div>
                    <!-- body -->
                    <div class="py-[16px] px-[24px]" v-if="layoutStore.confirm?.detail">
                      <template v-if="Array.isArray(layoutStore.confirm?.detail)">
                        <div class="h-[40px] flex items-center justify-center">
                          <div>
                            <p
                              v-for="(detail, index) in layoutStore.confirm?.detail"
                              :key="index"
                              class="text-center label_6 text-[#383838] whitespace-pre-line flex items-center justify-center"
                              >
                              {{ detail }}
                            </p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p
                          class="label_6 text-[#383838] whitespace-pre-line h-[40px] flex items-center justify-center">
                          {{ layoutStore.confirm?.detail }}
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <!-- bottom -->
              <div class="flex grow w-full">
                <!-- 취소 버튼 -->
                <button
                  class="py-[16px] px-[24px] flex justify-center w-full label_5"
                  :class="layoutStore.confirm?.rejectClass"
                  @click="reject">
                  {{ layoutStore.confirm?.rejectString ?? "취소" }}
                </button>
                <!-- 확인 버튼 -->
                <button
                  class="py-[16px] px-[24px] flex justify-center w-full label_5"
                  :class="layoutStore.confirm?.acceptClass"
                  @click="accept">
                  {{ layoutStore.confirm?.acceptString ?? "확인" }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>

<style scoped>
.close_button {
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
}
.close_button img {
  width: 20px;
  height: 20px;
}

.cancel_button {
  color: #b4b4b4;
  height: 40px;
  width: 50%;
  border-radius: 4px;
  background: white;
  border: 1px solid var(--G200, #b4b4b4);
}
.okay_button {
  color: white;
  height: 40px;
  width: 50%;
  border-radius: 4px;
  background: var(--Primary, #1e64ff);
}
</style>
