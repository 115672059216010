<template>
  <div
    v-if="!isMobile"
    class="flex flex-row-reverse h-screen overflow-hidden"
    style="background-color: #ebf4ff">
    <div class="flex flex-col-reverse z-20" style="width: 280px">
      <div id="pc-sidebar">
        <div
          class="sidebar-icon"
          @click="openUrl('https://www.youtube.com/@zguhada', '유튜브')">
          <img src="/src/assets/icons/ic_youtube.svg" />
        </div>
        <div
          class="sidebar-icon"
          @click="openUrl('https://blog.naver.com/zguhada', '네이버')">
          <img src="/src/assets/icons/ic_blog.svg" />
        </div>
        <div
          class="sidebar-icon"
          @click="openUrl('https://www.instagram.com/zguhada/', '인스타그램')">
          <img src="/src/assets/icons/ic_inatagram.svg" />
        </div>
        <div class="qr-box">
          <img src="/src/assets/icons/ic_qrcode.svg" />
          <div style="height: 10px"></div>
          <div class="qr-title select-none">지구하다 앱</div>
          <div class="qr-title select-none">다운로드</div>
          <div style="height: 4px"></div>
          <div class="qr-subtitle select-none">QR코드를</div>
          <div class="qr-subtitle select-none">스캔해 보세요!</div>
        </div>
      </div>
    </div>
    <div
      style="min-width: 520px; max-width: 520px; position: relative"
      class="z-20">
      <router-view></router-view>
    </div>
    <div class="flex-grow flex">
      <div style="width: 300px"></div>
      <div id="pc-title" class="z-10">
        <img
          id="pc-logo"
          src="/src/assets/icons/logo/logo.svg"
          @click="logoClickEvent"
          class="cursor-pointer" />
        <div class="title select-none">지구를 구하는</div>
        <div class="title select-none" style="display: inline-block">
          가장&nbsp;
        </div>
        <div style="display: inline-block" class="title-important select-none">
          쉬운 방법!
        </div>
        <div style="height: 12px"></div>
        <div class="subtitle select-none">
          폐기물 처리 플랫폼 지구하다와 함께
        </div>
        <div class="subtitle select-none">올바른 폐기 함께 실천해요.</div>
      </div>
      <img
        src="/src/assets/images/pc_background.png"
        class="pc-background select-none" />
    </div>
  </div>
  <div class="overflow-hidden" v-else>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { RouterLink, RouterView } from "vue-router";
import { useAuthStore, cookieZguhada } from "./stores/authStore";
import { useWebviewStore } from "@/stores/webviewStore";
import { useEventListener } from "@vueuse/core";
import router, { RoutesData } from "@/router/index";
import { useRoute } from "vue-router";
import Cookies from "js-cookie";
import { useLayoutStore } from "@/stores/layoutStore";
import { useRequestStore } from "./stores/requestStore";
import { gaEvent } from "@/utils/utils";

const { VITE_KAKAO_JS_KEY } = import.meta.env;

const authStore = useAuthStore();
const webviewStore = useWebviewStore();
const layoutStore = useLayoutStore();
const requestStore = useRequestStore();

// web - pc, mobile 사이즈 분기처리
const targetSize = 720;
const isMobile = ref(false);

async function provideZguhadaToken(event) {
  console.log("provideZguhadaToken", JSON.stringify(event.detail));

  await authStore.provideToken(event.detail.access, event.detail.refresh);
}

/*
"window.setMobileWebviewData({'isMobileWebview':'true', 'isAndroid':'${Platform.isAndroid}', 'isIos': '${Platform.isIOS}', 'isDebug': '${kDebugMode}'});");
*/

function resizeWindow(event) {
  isMobile.value = window.innerWidth < targetSize;
  layoutStore.isMobileSize = window.innerWidth < targetSize;
}

function setMobileWebviewData(event) {
  console.log("setMobileWebviewData: ", JSON.stringify(event.detail));
  webviewStore.webviewData = event.detail;
  requestStore.channel = "zg_app";
}

async function setDefaultUser() {
  if (authStore.isLogined()) {
    await authStore.requestLoginInfo();
  }
}

/*
로그인 되어있을시 web토큰을 mobile에 전달.

 */
function setMobileToken() {
  if (window.flutter_inappwebview) {
    if (authStore.isLogined()) {
      window.flutter_inappwebview.callHandler("loginSuccess", {
        access: authStore.access,
        refresh: authStore.refresh,
        profile: authStore.user,
        doRoute: false,
      });
    } else {
      // token remove ??
    }
  }
}

function setIsMobile() {
  const userAgent =
    navigator.userAgent.toLowerCase() || navigator.vendor || window.opera;

  const isMobileAgent =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );

  // 모바일 여부 설정
  layoutStore.isMobileAgent = isMobileAgent;
}

onMounted(() => {
  if (Kakao.isInitialized) {
    Kakao.init(VITE_KAKAO_JS_KEY); // Kakao login때 사용
  }

  webviewStore.webviewData = null;
  setIsMobile();
  setMobileToken();
  setDefaultUser();
  resizeWindow();

  useEventListener("provideZguhadaToken", provideZguhadaToken);
  useEventListener("setMobileWebviewData", setMobileWebviewData);

  window.addEventListener("resize", resizeWindow, { passive: true });

  const urlParams = new URLSearchParams(window.location.search);
  webviewStore.fromWeb = urlParams.get("fromWeb") === "true";

  console.log("is mobile size :" + isMobile.value);
});

onBeforeUnmount(() => {
  // authStore.removeSessionToken();
  window.removeEventListener("resize", resizeWindow, { passive: true });
});

function logoClickEvent() {
  router.push(RoutesData.Main.path);
}

function openUrl(url, name) {
  gaEvent("sns_click", { sns_name: name });
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("openBrowser", {
      url: url,
    });
  } else {
    window.open(url);
  }
}
</script>

<style scoped>
#pc-title {
  margin-top: 160px;
}

#pc-title .title {
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

#pc-title .subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

#pc-title .title-important {
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  color: #3182ff;
}

#pc-logo {
  width: 162px;
  height: 32px;
  margin-bottom: 60px;
}

#pc-sidebar {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 16px;
  margin-bottom: 92px;
}

.sidebar-icon {
  display: flex;
  width: 72px;
  height: 72px;
  border-radius: 24px;
  border: 1px solid rgba(204, 213, 226, 0.12);
  background-color: rgba(17, 17, 17, 0.08);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.qr-box {
  margin-top: 12px;
  padding: 24px;
  gap: 10px;
  margin-right: auto;
  border-radius: 16px;
  border: 1px solid rgba(204, 213, 226, 0.12);
  background: #fbfdff;

  /* Shadow 100 */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
}

.qr-title {
  color: #111;

  /* Head6 */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.qr-subtitle {
  color: #7c8391;

  /* Body2 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.pc-background {
  position: absolute;
  left: 68px;
  bottom: 92px;
  object-fit: cover;
  width: 60%;
  max-width: 1000px;
}
</style>
