<template>
  <div style="position: relative">
    <div
      class="bottom-navigation flex flex-row justify-center align-center gap-8"
      :class="[
        isWebMobile ? 'pb-[24px]' : 'pb-[44px]',
        isWebMobile ? 'h-[75px]' : 'h-[95px]',
        props.blurBottom ? 'blur-bottom' : 'back-white',
      ]">
      <div
        class="navigation-item"
        v-for="item in bottomNavItems"
        @click="onClickNavigationItem(item)">
        <img
          :src="
            item.index === navigationStore.selectedIndex
              ? item.selectedIcon
              : item.icon
          " />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useNavigationStore } from "@/stores/navigationStore.js";
import router from "@/router/index";
import { RoutesData } from "@/router";
import { useAuthStore } from "@/stores/authStore";
import { useLayoutStore } from "@/stores/layoutStore";
import { gaEvent } from "@/utils/utils";

const authStore = useAuthStore();
const navigationStore = useNavigationStore();
const layoutStore = useLayoutStore();

const props = defineProps({
  blurBottom: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const isWebMobile = computed(() => {
  return layoutStore.isMobileSize;
});

const bottomNavItems = ref([
  {
    index: 0,
    targetPath: RoutesData.Main.children.Main.path,
    icon: new URL("@/assets/icons/nav/icon_nav_home.svg", import.meta.url).href,
    selectedIcon: new URL(
      "@/assets/icons/nav/icon_nav_home_selected.svg",
      import.meta.url
    ).href,
    name: "홈",
  },
  {
    index: 1,
    targetPath: RoutesData.Main.children.MyHistory.path,
    icon: new URL("@/assets/icons/nav/icon_nav_calendar.svg", import.meta.url)
      .href,
    selectedIcon: new URL(
      "@/assets/icons/nav/icon_nav_calendar_selected.svg",
      import.meta.url
    ).href,
    name: "이용내역",
  },
  {
    index: 2,
    targetPath: RoutesData.CollectionPlace.children.CollectionPlaceMap.path,
    icon: new URL("@/assets/icons/nav/icon_nav_map.svg", import.meta.url).href,
    selectedIcon: new URL(
      "@/assets/icons/nav/icon_nav_map_selected.svg",
      import.meta.url
    ).href,
    name: "집하장찾기",
  },
  {
    index: 3,
    targetPath: RoutesData.Main.children.MyPage.path,
    icon: new URL("@/assets/icons/nav/icon_nav_mypage.svg", import.meta.url)
      .href,
    selectedIcon: new URL(
      "@/assets/icons/nav/icon_nav_mypage_selected.svg",
      import.meta.url
    ).href,
    name: "유튜브",
  },
]);

const updateNavigation = ({ index, path, query }) => {
  navigationStore.chnageSelectedIndex(index);
  router.replace({
    path,
    query,
  });
};

function onClickNavigationItem(item) {
  gaEvent("navi_click", { navi_name: item.name });
  switch (item.index) {
    case 0:
      updateNavigation({
        index: item.index,
        path: item.targetPath,
        query: {
          type: "collect",
        },
      });
      break;
    case 1:
    case 3:
      if (authStore.isLogined()) {
        updateNavigation({
          index: item.index,
          path: item.targetPath,
        });
      } else {
        if (item.index === 1) {
          layoutStore.showAlertDialog(
            "로그인이 필요합니다",
            "회원가입페이지로 이동합니다.",
            "확인",
            () => {
              updateNavigation({
                index: 3,
                path: RoutesData.Main.children.Login.path,
              });
            }
          );
        } else {
          if (item.index === 3) {
            updateNavigation({
              index: 3,
              path: RoutesData.Main.children.Login.path,
            });
          }
        }
      }
      break;
    default:
      updateNavigation({
        index: item.index,
        path: item.targetPath,
      });
  }
}
</script>

<style scoped>
.bottom-navigation {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  /* height: 95px; */
  width: 100%;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 48px;
  /* transform: translateY(-100%); */
}
.back-white {
  background: white;
}
.blur-bottom {
  background: var(--white-88, rgba(255, 255, 255, 0.88));
  backdrop-filter: blur(3px);
}

.navigation-item img {
  width: 35px;
  height: 35px;

  cursor: pointer;
}
</style>
