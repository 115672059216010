import { HttpMethods } from "@/utils/constant";
import authAxiosServices from "@/service/axios/authAxios";

// SMS 인증번호 요청
export const putCellPhoneAuth = async (cellphone) => {
  const url = `/common/cellphoneauth/simple/`;

  const response = await authAxiosServices({
    method: HttpMethods.put,
    url: url,
    data: {
      cellphone: cellphone,
    },
  });

  return response.data;
};

// 인증번호 인증 요청
export const postCellphoneAuth = async (id, authNo) => {
  const url = `/zguhada/cellphone/auth/`;

  const response = await authAxiosServices({
    method: HttpMethods.post,
    url: url,
    data: {
      id: id,
      auth_no: authNo,
    },
  });

  return response.data;
};

// 전화번호 로그인
export const postPhoneNumberLogin = async (cellphone, password) => {
  try {
    const url = `/zguhada/login/number/`;

    const response = await authAxiosServices({
      method: HttpMethods.post,
      url: url,
      data: {
        cellphone: cellphone,
        password: password,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// sns 회원가입
export const postSnsRegister = async ({
  name,
  phone_auth_id,
  social_type,
  social_id,
  social_refresh,
  old_14,
  personal_info,
  service_use,
  location_info,
  marketing_push,
  marketing_sms,
}) => {
  try {
    const url = `/zguhada/register/sns/`;

    const response = await authAxiosServices({
      method: HttpMethods.post,
      url: url,
      data: {
        name: name,
        phone_auth_id: phone_auth_id,
        social_type: social_type,
        social_id: social_id,
        social_refresh: social_refresh,
        old_14: old_14,
        personal_info: personal_info,
        service_use: service_use,
        location_info: location_info,
        marketing_push: marketing_push,
        marketing_sms: marketing_sms,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postRegister = async ({
  phone_auth_id,
  name,
  password,
  old_14,
  personal_info,
  service_use,
  location_info,
  marketing_push,
  marketing_sms,
}) => {
  try {
    const response = await authAxiosServices({
      method: HttpMethods.post,
      url: `/zguhada/register2/`,
      data: {
        phone_auth_id: phone_auth_id,
        name: name,
        password: password,
        old_14: old_14,
        personal_info: personal_info,
        service_use: service_use,
        location_info: location_info,
        marketing_push: marketing_push,
        marketing_sms: marketing_sms,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postPasswordAuth = async ({ user_id, user_name }) => {
  try {
    const response = await authAxiosServices({
      method: HttpMethods.post,
      url: `/zguhada/password/auth/`,
      data: {
        user_id: user_id,
        user_name: user_name,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postPasswordChange = async ({ phone_auth_id, password }) => {
  try {
    const url = `zguhada/password/change/`;

    const response = await authAxiosServices({
      method: HttpMethods.post,
      url: url,
      data: {
        phone_auth_id: phone_auth_id,
        password: password,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
