import { defineStore } from "pinia";
import { useLocalStorage, useSessionStorage } from "@vueuse/core";

const serializer = {
  read: (v) => {
    try {
      return v ? JSON.parse(v) : null;
    } catch (error) {
      return null;
    }
  },
  write: (v) => JSON.stringify(v),
};

export const useWebviewStore = defineStore({
  id: "webview",
  state: () => ({
    webviewData: useSessionStorage("webview_data", null, { serializer }),    
  }),
});
