<template>
  <GlobalOverlay />
  <Notifications />
  <AlertDialog />
  <ConfirmDialog />
  <div class="m-web-height" style="background-color: #fbfdff;">
    <router-view />
  </div>

  <bottom-navigation style="z-index: 10;" :blurBottom="true"></bottom-navigation>
</template>

<script setup>
import Notifications from "@/components/alert/Notifications.vue";
import AlertDialog from "@/components/alert/AlertDialog.vue";
import ConfirmDialog from "@/components/alert/ConfirmDialog.vue";
import GlobalOverlay from "@/components/overlay/GlobalOverlay.vue";
import { RouterView } from "vue-router";
import BottomNavigation from "@/components/layout/BottomNavigation.vue";
import { useRoute } from "vue-router";

const route = useRoute();

</script>

<style scoped></style>
