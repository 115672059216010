<script setup>
import { useLayoutStore } from "@/stores/layoutStore.js";
import { useElementBounding, useWindowSize } from "@vueuse/core";
import { computed, ref } from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { Severities } from "@/utils/constant";

const layoutStore = useLayoutStore();

const notificationWrapper = ref(null);

const { width: windowWidth, height: windowHeight } = useWindowSize();

const { x, y, top, right, bottom, left, width, height } = useElementBounding(
  notificationWrapper,
  { immediate: false }
);

const wrapperStyle = computed(() => {
  return {
    left: `${(windowWidth.value - width.value) / 2}px`,
  };
});

const bgColor = (severity) => {
  switch (severity) {
    case Severities.ERROR:
      return "bg-red-400";
    case Severities.SECONDARY:
      return "bg-green-400";
    case Severities.PRIMARY:
      return "bg-primary-400";
    default:
      return "bg-primary-400";
  }
};
</script>

<template>
  <Teleport to="body">
    <div
      ref="notificationWrapper"
      :style="wrapperStyle"
      class="w-full xl:w-96 absolute top-[3.25rem] mt-2">
      <TransitionGroup>
        <template
          v-for="notification in layoutStore.notifications"
          v-bind:key="notification.uid">
          <div class="mb-3 rounded-md shadow-2xl">
            <div
              :class="[
                bgColor(notification.severity),
                notification.detail ? 'rounded-t-md py-1' : 'rounded-md py-2',
              ]"
              class="text-white px-2 py-1 text-sm flex items-center justify-between">
              <div>{{ notification.summary }}</div>
              <button
                class="rounded-sm"
                @click="layoutStore.removeNotification(notification)">
                <XMarkIcon class="size-4 text-black" />
              </button>
            </div>
            <div
              v-if="notification.detail"
              class="bg-white text-black rounded-b-md px-2 py-1 text-sm border-l border-b border-r">
              <template v-if="Array.isArray(notification.detail)">
                {{ notification.detail }}
              </template>
              <template v-else>
                {{ notification.detail }}
              </template>
            </div>
          </div>
        </template>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.25s ease-out;
}

.v-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.v-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}
</style>
