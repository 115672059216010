import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import generateUuidString from "@/utils/generateUuidString.js";
import { Severities } from "@/utils/constant";
import { nextTick } from "vue";
import router, { RoutesData } from "@/router";

export const useLayoutStore = defineStore({
  id: "layout",
  state: () => ({
    overlay: false,
    notifications: [],
    // serializer 설정을 하지 않은 경우 새로고침한 후 값이 변형되는 오류 발생
    showConfirm: false,
    confirm: useLocalStorage("confirm", null, {
      serializer: { read: JSON.parse, write: JSON.stringify },
    }),
    showAlert: false,
    alert: useLocalStorage("alert", null, {
      serializer: { read: JSON.parse, write: JSON.stringify },
    }),
    showToast: false,
    toast: useLocalStorage("toast", null, {
      serializer: { read: JSON.parse, write: JSON.stringify },
    }),
    isMobileAgent: false, // pc web 환경인지 모바일 web 환경인지,
    isMobileSize: false, // web 사이즈인지 모바일 사이즈인지
  }),
  actions: {
    // 우상단에 작게 노출되는 알림 표시
    addNotification(severity, summary, detail) {
      let uid = generateUuidString();
      let notification = { uid: uid, severity, summary, detail };
      this.notifications.push(notification);
      setTimeout(() => {
        this.removeNotification(notification);
      }, 5000);
    },
    addSuccessNotification(summary, detail) {
      this.addNotification(Severities.PRIMARY, summary, detail);
    },
    addInfoNotification(summary, detail) {
      this.addNotification(Severities.SECONDARY, summary, detail);
    },
    addErrorNotification(summary, detail) {
      this.addNotification(Severities.ERROR, summary, detail);
    },
    removeNotification(notification) {
      this.notifications = this.notifications.filter(
        (n) => n.uid !== notification.uid
      );
    },
    openToast(detail) {
      this.showToast = true;
      this.toast = {
        detail,
      };
      setTimeout(() => {
        this.closeToast();
      }, 2000);
    },
    closeToast() {
      this.showToast = false;
    },
    // "확인" 다이얼로그
    showAlertDialog(
      summary,
      detail,
      acceptString,
      acceptCallback,
      rightBtn,
      showLogo
    ) {
      this.showAlert = true;
      this.alert = {
        summary,
        detail,
        acceptString: acceptString || "확인",
        acceptCallback: acceptCallback || (() => {}),
        rightBtn: rightBtn || "default-txt-color", // 오른쪽 버튼은 색상이 다른 경우가 있어서
        isShow: true,
        showLogo: showLogo || false,
      };
    },
    async alertAccept() {
      if (this.alert?.acceptCallback) {
        await this.alert.acceptCallback();
      }
      this.closeAlert();
    },
    closeAlert() {
      this.showAlert = false;
    },
    clearAlertData() {
      this.alert = null;
    },
    // "취소", "확인" 다이얼로그
    showConfirmDialog(
      summary,
      detail,
      acceptString,
      rejectString,
      acceptCallback,
      rejectCallback,
      acceptClass,
      rejectClass
    ) {
      this.showConfirm = true;
      this.confirm = {
        summary,
        detail,
        acceptString: acceptString || "확인",
        rejectString: rejectString || "취소",
        acceptCallback: acceptCallback || (() => {}),
        rejectCallback: rejectCallback || (() => {}),
        acceptClass: acceptClass || null,
        rejectClass: rejectClass || null,
      };
    },
    async confirmAccept() {
      if (this.confirm?.acceptCallback) {
        await this.confirm.acceptCallback();
      }
      this.closeConfirm();
    },
    async confirmReject() {
      if (this.confirm?.rejectCallback) {
        await this.confirm.rejectCallback();
      }
      this.closeConfirm();
    },
    closeConfirm() {
      this.showConfirm = false;
    },
    clearConfirm() {
      this.confirm = null;
    },
    // 로딩 overlay
    showOverlay() {
      this.overlay = true;
    },
    hideOverlay() {
      this.overlay = false;
    },
    showUseMobileAlert() {
      this.showConfirmDialog(
        "앱에서 이용 가능한 서비스입니다",
        "앱 설치 후 이용해 주세요.",
        "애플",
        "안드로이드",
        () => {
          window.open("https://apps.apple.com/kr/app/id6459451210");
        },
        () => {
          window.open(
            "https://play.google.com/store/apps/details?id=com.chunil.zguhada.platform.app.zguhada_platform"
          );
        },
        "text-[16px] font-medium text-[#7C8391]",
        "text-[16px] font-medium text-[#7C8391]"
      );
    },
    showNeedLoginAlert() {
      this.showAlertDialog(
        "로그인이 필요합니다",
        "회원가입페이지로 이동합니다.",
        "확인",
        () => {
          router.replace(RoutesData.Main.children.Login.path);
        },
        "text-[#3182FF] text-[16px] font-medium"
      );
    },
    showErrorAlert(message, action) {
      this.showAlertDialog("고객센터 문의가 필요합니다.", message, "확인", () => {
        if (action != null) {
          action();
        }
        this.closeAlert();
      });
    },
  },
});
