<template>
  <AlertDialog />
  <ConfirmDialog />
  <Toast />
  <div class="container overflow-hidden m-web-height"
    :class="[
      !isWebMobile ? 'pt-[30px]' : '',
    ]">
    <div :class="route.path !== RoutesData.Blw.children.BlwSearchItem.path ? 'blur-nav' : ''">
      <div class="nav">
        <div class="flex flex-row">
          <button @click="onClickBack">
            <img class="back_button" :src="icon_arrow_left" />
          </button>
          <div class="flex items-center">
            <div class="body_5 back_content">대형 생활 폐기물</div>
          </div>
        </div>
        <div class="flex items-center">
          <button
            class="pr-4"
            @click="onClickCustomerSupportButton">
            <img src="@/assets/icons/icon_callcenter.svg" />
          </button>
        </div>
      </div>
    </div>
    <!-- body -->
    <div class="body" :style="route.path === RoutesData.Blw.children.BlwSearchItem.path ? { overflow: 'hidden' } : {}">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import router, { RoutesData } from "@/router";
import { useRoute } from 'vue-router'
import { computed, onMounted } from "vue";
import icon_arrow_left from "@/assets/icons/icon_arrow_left_black.svg";
import { useLayoutStore } from "@/stores/layoutStore.js";
import AlertDialog from "@/components/alert/AlertDialog.vue";
import ConfirmDialog from "@/components/alert/ConfirmDialog.vue";
import Toast from "@/components/alert/Toast.vue";

const layoutStore = useLayoutStore()
const route = useRoute()

// const hideHeader = computed(() => {
//   console.log('window location pathname: ', route.path);
//   console.log('blw search item path: ', RoutesData.Blw.children.BlwSearchItem.path);
//   return (
//     route.path === RoutesData.Blw.children.BlwSearchItem.path
//   );
// });

const isWebMobile = computed(() => {  
  return layoutStore.isMobileSize;
});

const onClickBack = () => {
  router.back();
}

const onClickCustomerSupportButton = () => {
  layoutStore.showConfirmDialog(
    "고객센터로 연결합니다",
    "바로 연결하시겠습니까?",
    "전화하기",
    "1:1 채팅하기",
    () => linkCallcenter(),
    () => linkKakaoChannelTalk(),
    "text-[#3182FF]",
    "text-[#7C8391]"
  );
};

const linkCallcenter = () => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("call", {
      contract: "1660-3114",
    });
  } else {
    document.location.href = `tel:1660-3114`;
  }
};

const linkKakaoChannelTalk = () => {
  if (window.flutter_inappwebview) {
    // Kakao.Channel.chat({
    //   channelPublicId: "_uZzxfG",
    // });
    window.flutter_inappwebview.callHandler("openBrowser", {
      url: "http://pf.kakao.com/_uZzxfG/chat",
    });
  } else {
    window.open("http://pf.kakao.com/_uZzxfG/chat");
  }
};

onMounted(() => {
  console.log();
});
</script>

<style scoped>
.container {
  background-color: #fbfdff;
}
.light_header {
  background: white;
}

.mobile_header_button_wrap {
  gap: 16px;
}

.blw_request_button {
  background-color: #1e64ff;
  border-radius: 4px;
  color: white;
  height: 37px;
  padding: 0px 12px;
}

@media screen and (max-width: 992px) {
  header {
    background-color: #123ba3;
    box-shadow: none;
  }

  header .menu_button img {
    width: 38px;
    height: 38px;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }
}

header .header_wrap {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  position: relative;
  padding: 0px 20px;
}

.back_button {
  height: 24px;
  width: 24px;
  margin: 8px 12px 8px 16px;
}
.back_content {
  margin: 8px 0;
}

.nav {
  height: 52px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  overflow-y: hidden;
}

.body {
  height: 100%;
  overflow: auto;
  width: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.body::-webkit-scrollbar {
  display: none;
}
</style>
