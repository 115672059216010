<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ExclamationTriangleIcon,
  CheckIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/outline";
import { useLayoutStore } from "@/stores/layoutStore.js";
import { Severities } from "@/utils/constant";
import router, { RoutesData } from "@/router/index";

const layoutStore = useLayoutStore();

const accept = () => {
  layoutStore.alertAccept();
};

const closeAlert = () => {
  layoutStore.alertAccept();
};

const detectAnimationLeave = () => {
  layoutStore.clearAlertData();
};

const btns = ref(layoutStore.alert?.acceptString ?? "확인");
</script>

<template>
  <Teleport to="body">
    <TransitionRoot :show="layoutStore.showAlert === true" as="template">
      <Dialog class="relative z-[999]">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity" />
        <div class="fixed inset-0 z-[999] flex items-center justify-center flex-wrap self-center">
          <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            @leave="detectAnimationLeave">
            <img
              v-if="layoutStore.alert?.showLogo"
              class="transform transition-all"
              src="@/assets/icons/icon_zgu.svg" />
            <DialogPanel
              class="transform overflow-hidden flex flex-col rounded-lg bg-white text-left shadow-xl transition-all mx-[16px]"
              style="
                min-width: 320px;
                width: calc(100% - 32px);
                max-width: 800px;
                max-height: 500px;
                border-radius: 16px;
              ">
              <div
                class="flex-grow bg-white"
                style="border-bottom: 1px solid rgba(204, 213, 226, 0.16)">
                <div class="sm:flex sm:items-start">
                  <div
                    class="alert-content w-full flex flex-col text-center align-center">
                    <!-- title -->
                    <div class="flex justify-center items-center h-[56px] mx-[24px] mt-[24px]">
                      <DialogTitle
                        as="h3"
                        class="text-center whitespace-pre-line">
                        <template
                          v-if="Array.isArray(layoutStore.alert?.summary)">
                          <p
                            v-for="(summary, index) in layoutStore.alert?.summary"
                            :key="index"
                            class="subtitle_8">
                            {{ summary }}
                          </p>
                        </template>
                        <template v-else
                          ><p class="subtitle_8">
                            {{ layoutStore.alert?.summary }}
                          </p></template
                        >
                      </DialogTitle>
                    </div>
                    <!-- body -->
                    <div class="py-[16px] px-[24px]" v-if="layoutStore.alert?.detail">
                      <template v-if="Array.isArray(layoutStore.alert?.detail)">
                        <div class="h-[40px] flex items-center justify-center">
                          <div>
                            <p
                              v-for="(detail, index) in layoutStore.alert?.detail"
                              :key="index"
                              class="text-center label_6 text-[#383838] whitespace-pre-line flex items-center justify-center"
                              >
                              {{ detail }}
                            </p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p
                          class="label_6 text-[#383838] whitespace-pre-line h-[40px] flex items-center justify-center">
                          {{ layoutStore.alert?.detail }}
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-full self-end align-end sm:flex sm:flex-row-reverse sm:px-6">
                <!-- <button
                  class="btn okay_button subtitle_7"
                  type="button"
                  @click="accept">
                  {{ layoutStore.alert?.acceptString ?? "확인" }}
                </button> -->
                <template v-if="Array.isArray(layoutStore.alert?.acceptString)">
                  <div class="flex grow">
                    <div
                      v-for="(btn, index) in btns"
                      :key="index"
                      class="py-[16px] px-[24px] flex justify-center w-full label_5"
                      @click="accept"
                      :class="
                        index === 1
                          ? layoutStore.alert.rightBtn
                          : 'default-txt-color'
                      ">
                      {{ btn }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="py-[16px] px-[24px] flex justify-center w-full label_5"
                    @click="accept"
                    :class="layoutStore.alert.rightBtn">
                    {{ btns }}
                  </div>
                </template>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>

<style scoped>
.close_button {
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
}
.close_button img {
  width: 20px;
  height: 20px;
}

.alert-content img {
  width: 42px;
  height: 42px;
  margin-bottom: 10px;
}

.okay_button {
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background: var(--Primary, #1e64ff);
}
.default-txt-color {
  color: #7c8391;
}
.blue-txt-color {
  color: #3182ff;
}
.black-txt-color {
  color: var(--G900, #111);
}
</style>
