<!-- 
  Router에서 할당받아서 사용하는게 아니라
  각 페이지마다 필요할때마다 가져가기,
  상단 네비게이션이 다른 경우도있으니 이렇게 처리.
 -->
<script setup>
import { ref, computed } from "vue";
import icon_arrow_left from "@/assets/icons/icon_arrow_left.svg";
import router, { RoutesData } from "@/router/index";
import { useLayoutStore } from "@/stores/layoutStore";
import { useRoute } from "vue-router";

const layoutStore = useLayoutStore();

const route = useRoute();

const isWebMobile = computed(() => {  
  return layoutStore.isMobileSize;
});

const props = defineProps({
  showBackButton: {
    type: Boolean,
    required: false,
    default: true,
  },
  pageVal: {
    type: String,
    required: false,
    default: null,
  },
});

// Action

const onClickBackButton = () => {
  if (
    route.path === RoutesData.Main.children.MyPage.path ||
    route.path === RoutesData.Main.children.Login.path
  ) {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("zguhadaWebviewClose");
    }
    return;
  }

  router.back();
};

const onClickCustomerSupportButton = () => {
  layoutStore.showConfirmDialog(
    "고객센터로 연결 하시겠습니까?",
    null,
    "전화하기",
    "1:1 채팅하기",
    () => linkCallcenter(),
    () => linkKakaoChannelTalk()
  );
};

const linkCallcenter = () => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("call", {
      contract: "1660-3114",
    });
  } else {
    document.location.href = `tel:1660-3114`;
  }
};

const linkKakaoChannelTalk = () => {
  if (window.flutter_inappwebview) {
    // Kakao.Channel.chat({
    //   channelPublicId: "_uZzxfG",
    // });
    window.flutter_inappwebview.callHandler("openBrowser", {
      url: "http://pf.kakao.com/_uZzxfG/chat",
    });
  } else {
    window.open("http://pf.kakao.com/_uZzxfG/chat");
  }
};
</script>

<template>
  <div
    :class="[props.showBackButton ? 'justify-between' : 'justify-end', !isWebMobile ? 'pt-[30px]' :'']">
    <div class="nav">
      <div class="flex items-center">
        <button v-if="props.showBackButton" @click="onClickBackButton">
          <img class="back_button" :src="icon_arrow_left" />
        </button>
        <div v-if="props.pageVal" class="body_5 back_content">{{ props.pageVal }}</div>
      </div>
      <button class="customer_service" @click="onClickCustomerSupportButton">
        <img src="@/assets/icons/icon_callcenter.svg" />
      </button>
    </div>
  </div>
  <div class="body bg-white"><slot></slot></div>
</template>

<style scoped>
.nav {
  height: 52px;
  width: 100%;
  background: white;
  display: flex;
  align-content: center;
  justify-content: space-between;
  overflow-y: hidden;
}

.body {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0 20px;
}

.back_button {
  height: 24px;
  width: 24px;
  margin: 8px 12px 8px 16px;
}
.back_content {
  margin: 8px 0;
}

.customer_service {
  color: #191919;
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
  margin-right: 20px;
  align-self: center;
}
</style>
