<template>
  <div class="nav">
      <div class="flex flex-row">
        <button v-if="!hideBackButton" @click="onClickBackButton">
          <img class="back_button" :src="icon_arrow_left" />
        </button>
        <div class="flex items-center">
          <div class="body_5 back_content">{{ props.title }}</div>
        </div>
      </div>
      <div class="flex items-center">
        <button
          :class="hideNoticeButton ? 'pr-4' : ''"
          @click="onClickCustomerSupportButton">
          <img src="@/assets/icons/icon_callcenter.svg" />
        </button>
        <button
          v-if="!hideNoticeButton"
          class="pr-4 pl-3"
          @click="onClickNoticeButton">
          <img src="@/assets/icons/icon_notice.svg" />
        </button>
      </div>
    </div>
</template>

<script setup>
import icon_arrow_left from "@/assets/icons/icon_arrow_left_black.svg";
import router from "@/router/index";
import { useLayoutStore } from "@/stores/layoutStore";
import { useAuthStore } from "@/stores/authStore";

const authStore = useAuthStore();
const layoutStore = useLayoutStore();

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: ''
  },
  hideBackButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideNoticeButton: {
    type: Boolean,
    required: false,
    default: false,
  }
})

// Action
const onClickBackButton = () => {
  // if (props.title === "폐기물 처리 문의" || props.title === "폐업·철거 견적 문의") {
  //   layoutStore.showConfirmDialog(
  //     "작성된 내용이 삭제됩니다.",
  //     "현재 화면에서 나가시겠습니까?",
  //     "계속 작성",
  //     "나가기",
  //     null,
  //     () => router.back(),
  //     "text-[#3182FF]",
  //     "text-[#7C8391]"
  //   );
  // } else {
    router.back();
  // }
};

const onClickCustomerSupportButton = () => {
  layoutStore.showConfirmDialog(
    "고객센터로 연결합니다",
    "바로 연결하시겠습니까?",
    "전화하기",
    "1:1 채팅하기",
    () => linkCallcenter(),
    () => linkKakaoChannelTalk(),
    "text-[#3182FF]",
    "text-[#7C8391]"
  );
};

const onClickNoticeButton = () => {
  if (window.flutter_inappwebview) {
    if (authStore.isLogined()) {
      window.flutter_inappwebview.callHandler("routeAlarm", {});
    } else {
      layoutStore.showNeedLoginAlert();
    }
  } else {
    layoutStore.showUseMobileAlert();
  }
};

const linkCallcenter = () => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("call", {
      contract: "1660-3114",
    });
  } else {
    document.location.href = `tel:1660-3114`;
  }
};

const linkKakaoChannelTalk = () => {
  if (window.flutter_inappwebview) {
    // Kakao.Channel.chat({
    //   channelPublicId: "_uZzxfG",
    // });
    window.flutter_inappwebview.callHandler("openBrowser", {
      url: "http://pf.kakao.com/_uZzxfG/chat",
    });
  } else {
    window.open("http://pf.kakao.com/_uZzxfG/chat");
  }
};
</script>

<style scoped>
.nav {
  height: 52px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  overflow-y: hidden;
}

.back_button {
  height: 24px;
  width: 24px;
  margin: 8px 12px 8px 16px;
}
.back_content {
  margin: 8px 0;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>