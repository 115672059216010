<!-- 
  Router에서 할당받아서 사용하는게 아니라
  각 페이지마다 필요할때마다 가져가기,
  상단 네비게이션이 다른 경우도있으니 이렇게 처리.
 -->
<script setup>
import { computed, onMounted, ref } from "vue";
import icon_arrow_left from "@/assets/icons/icon_arrow_left_black.svg";
import router, { RoutesData } from "@/router/index";
import { useLayoutStore } from "@/stores/layoutStore";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import ConfirmDialog from "@/components/alert/ConfirmDialog.vue";
import { gaEvent } from "@/utils/utils";

const authStore = useAuthStore();
const layoutStore = useLayoutStore();

// const hasNavigation = computed(() => route.meta.navigationIndex != null);
const isWebMobile = computed(() => {
  return layoutStore.isMobileSize;
});

const route = useRoute();

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "",
  },
  hideBackButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideNoticeButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasNavigation: {
    type: Boolean,
    required: false,
    default: false,
  },
  noNav: {
    type: Boolean,
    required: false,
    default: false,
  },
  isBanner: {
    type: Boolean,
    required: false,
    default: false,
  },
}); // detail 페이지일때만 값 o

// Action
const onClickBackButton = () => {
  router.back();
};

const onClickCustomerSupportButton = () => {
  gaEvent("click_event", { header_name: "알림내역" });
  layoutStore.showConfirmDialog(
    "고객센터로 연결합니다",
    "바로 연결하시겠습니까?",
    "전화하기",
    "1:1 채팅하기",
    () => linkCallcenter(),
    () => linkKakaoChannelTalk(),
    "text-[#3182FF]",
    "text-[#7C8391]"
  );
};

const onClickNoticeButton = () => {
  gaEvent("click_event", { header_name: "알림내역" });
  if (window.flutter_inappwebview) {
    if (authStore.isLogined()) {
      window.flutter_inappwebview.callHandler("routeAlarm", {});
    } else {
      layoutStore.showNeedLoginAlert();
    }
  } else {
    layoutStore.showUseMobileAlert();
  }
};

const linkCallcenter = () => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("call", {
      contract: "1660-3114",
    });
  } else {
    document.location.href = `tel:1660-3114`;
  }
};

const linkKakaoChannelTalk = () => {
  if (window.flutter_inappwebview) {
    // Kakao.Channel.chat({
    //   channelPublicId: "_uZzxfG",
    // });
    window.flutter_inappwebview.callHandler("openBrowser", {
      url: "http://pf.kakao.com/_uZzxfG/chat",
    });
  } else {
    window.open("http://pf.kakao.com/_uZzxfG/chat");
  }
};
</script>

<template>
    <div class="container m-web-height">
    <ConfirmDialog v-if="props.isBanner" />
    <div :class="!isWebMobile ? 'pt-[30px]' : ''" v-if="!noNav">
      <div class="nav">
        <div class="flex flex-row">
          <button v-if="!hideBackButton" @click="onClickBackButton">
            <img class="back_button" :src="icon_arrow_left" />
          </button>
          <div class="flex items-center">
            <div class="body_5 back_content">{{ props.title }}</div>
          </div>
        </div>
        <div class="flex items-center">
          <button
            :class="hideNoticeButton ? 'pr-4' : ''"
            @click="onClickCustomerSupportButton">
            <img src="@/assets/icons/icon_callcenter.svg" />
          </button>
          <button
            v-if="!hideNoticeButton"
            class="pr-4 pl-3"
            @click="onClickNoticeButton">
            <img src="@/assets/icons/icon_notice.svg" />
          </button>
        </div>
      </div>
    </div>
      <div
      v-if="!props.title"
      class="body"
      >
      <slot></slot>
    </div>
      <div
      v-else
      class="body"
      >
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
/* navigation 유무로 판단 */
.container {
  background-color: #fbfdff;
  /* padding-bottom: 95px; */
}
.nav {
  height: 52px;
  width: 100%;
  background: #fbfdff;
  display: flex;
  align-content: center;
  justify-content: space-between;
  overflow-y: hidden;
}

.body {
  height: 100%;
  overflow: auto;
  width: 100%;

  -ms-overflow-style: none;
}

.body::-webkit-scrollbar {
  display: none;
}

.back_button {
  height: 24px;
  width: 24px;
  margin: 8px 12px 8px 16px;
}

.back_content {
  margin: 8px 0;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
