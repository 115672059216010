import { createRouter, createWebHistory } from "vue-router";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import MainLayout from "@/components/layout/MainLayout.vue";
import BlwBaseLayout from "@/components/layout/BlwBaseLayout.vue";
import HomePage from "@/views/HomePage.vue";
import { useLayoutStore } from "@/stores/layoutStore";
import { useAuthStore } from "@/stores/authStore";
import { useNavigationStore } from "@/stores/navigationStore";
import { gaEvent, gaScreenEvent } from "@/utils/utils";

export const RoutesData = {
  // // routes wide하게 name과 path가 중복되면 안됨!
  Auth: {
    path: "/auth",
    component: BaseLayout,
    children: {
      // Login: {
      //   path: "/auth/login",
      //   name: "Login",
      //   meta: { authRequired: false, navigationIndex: 3 },
      //   component: () => import("@/views/auth/LoginPage.vue"),
      // },
      PasswordFindAuthStep1: {
        path: "/auth/password-find/step-01",
        name: "password-find-step01",
        component: () => import("@/views/auth/PasswordFindStep1Page.vue"),
      },
      PasswordFindAuthStep2: {
        path: "/auth/password-find/step-02",
        name: "password-find-step02",
        component: () => import("@/views/auth/PasswordFindStep2Page.vue"),
      },
      PasswordReset: {
        path: "/auth/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordResetPage.vue"),
      },
      SignupTerms: {
        path: "/auth/signup-terms",
        name: "signup-terms",
        meta: { authRequired: false },
        component: () => import("@/views/auth/SignupTermsPage.vue"),
      },
      SignupAuth: {
        path: "/auth/signup-auth",
        name: "signup-auth",
        meta: { authRequired: false },
        component: () => import("@/views/auth/SignupAuthPage.vue"),
      },
      SignupInput: {
        path: "/auth/signup-input",
        name: "signup-input",
        meta: { authRequired: false },
        component: () => import("@/views/auth/SignupInputPage.vue"),
      },
      SnsCallBack: {
        path: "/auth/callback/sns",
        name: "AuthCallbackSns",
        component: () => import("@/views/auth/callback/SnsCallback.vue"),
      },
      SignupSuccess: {
        path: "/auth/signup/success",
        name: "SignupSuccess",
        component: () => import("@/views/auth/SignupSuccessPage.vue"),
      },
    },
  },
  // Logout: {path: '/auth/login', name: 'logout', component: LogoutPage, meta: {authRequired: true}},
  Main: {
    path: "/",
    component: MainLayout,
    children: {
      Login: {
        path: "/auth/login",
        name: "Login",
        meta: { authRequired: false, navigationIndex: 3 },
        component: () => import("@/views/auth/LoginPage.vue"),
      },
      MyPage: {
        path: "/my",
        name: "my",
        meta: {
          authRequired: true,
          navigationIndex: 3,
          screenName: "마이페이지",
        },
        component: () => import("@/views/mypage/MyPage.vue"),
      },
      MyHistory: {
        path: "/my-history",
        name: "my-history",
        meta: {
          authRequired: true,
          navigationIndex: 1,
          screenName: "이용내역",
        },
        component: () => import("@/views/mypage/MyHistoryPage.vue"),
      },
      // CollectionPlaceMap: {
      //   path: "/collection-place/map",
      //   name: "collection-place-map",
      //   meta: { navigationIndex: 2 },
      //   component: () => import("@/views/map/CollectionPlaceMapPage.vue"),
      // },
      Main: {
        path: "/",
        name: "main",
        meta: { navigationIndex: 0 },
        component: () => import("@/views/request/MainPage.vue"),
      },
    },
  },
  My: {
    path: "/my",
    component: BaseLayout,
    children: {
      MyInfo: {
        path: "/my/info",
        name: "my-info",
        meta: { authRequired: true, screenName: "회원정보" },
        component: () => import("@/views/mypage/MyInfoPage.vue"),
      },
    },
  },
  CollectionPlace: {
    path: "/collection-place",
    component: BaseLayout,
    children: {
      CollectionPlaceMap: {
        path: "/collection-place/map",
        name: "collection-place-map",
        meta: { navigationIndex: 2, screenName: "집하장찾기" },
        component: () => import("@/views/map/CollectionPlaceMapPage.vue"),
      },
      CollectionPlaceList: {
        path: "/collection-place/list",
        name: "collection-place-list",
        meta: { screenName: "집하장안내" },
        component: () =>
          import("@/views/collection-place/CollectionPlaceListPage.vue"),
      },
      CollectionPlaceSearch: {
        path: "/collection-place/search",
        name: "집하장찾기",
        meta: { screenName: "집하장검색" },
        component: () => import("@/views/map/CollectionPlaceSearchPage.vue"),
      },
      CollectionPlaceFee: {
        path: "/collection-place/fee",
        name: "collection-place-fee",
        component: () => import("@/views/map/CollectionPlaceFeePage.vue"),
      },
    },
  },
  NotFound: {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/NotFoundPage.vue"),
  },
  BannerDetail: {
    path: "/banner",
    name: "banner-detail",
    component: () => import("@/views/main/BannerDetailPage.vue"),
  },
  Blw: {
    path: "/blw",
    component: BlwBaseLayout,
    children: {
      BlwSelectDate: {
        path: "/blw/request01",
        name: "blw-request01",
        component: () => import("@/views/blw/MobileBlwSelectDatePage.vue"),
      },
      BlwInputData: {
        path: "/blw/request02",
        name: "blw-request02",
        component: () => import("@/views/blw/MobileBlwInputDataPage.vue"),
      },
      BlwSelectItem: {
        path: "/blw/request03",
        name: "blw-request03",
        component: () => import("@/views/blw/MobileBlwSelectItemPage.vue"),
      },
      BlwSearchItem: {
        path: "/blw/search-item",
        name: "blw-search-item",
        component: () => import("@/views/blw/MobileBlwSearchItemPage.vue"),
      },
      BlwCheckInfo: {
        path: "/blw/check-info",
        name: "blw-check-info",
        component: () => import("@/views/blw/MobileBlwCheckInfoPage.vue"),
      },
      BlwComplete: {
        path: "/blw/complete",
        name: "blw-complete",
        component: () => import("@/views/blw/MobileBlwRequestCompletePage.vue"),
      },
    },
  },
  Request: {
    path: "/request",
    component: BaseLayout,
    children: {
      // Main: {
      //   path: "/request/main",
      //   name: "main",
      //   meta: { authRequired: false },
      //   component: () => import("@/views/ServiceMain.vue"),
      // },
      ConstructDetail: {
        path: "/request/detail/construct",
        name: "request-construct",
        meta: { screenName: "폐기물처리신청" },
        component: () => import("@/views/request/ConstructDetail.vue"),
      },
      BlwDetail: {
        path: "/request/detail/blw",
        name: "request-blw",
        meta: { screenName: "배출신고(직접버림)" },
        component: () => import("@/views/request/BlwDetail.vue"),
      },
      FeeDetail: {
        path: "/request/detail/fee",
        name: "request-fee",
        component: () => import("@/views/request/FeeDetail.vue"),
      },
      AskQuoteDetail: {
        path: "/request/detail/quote",
        name: "request-quote",
        meta: { screenName: "폐업철거견적문의" },
        component: () => import("@/views/request/AskQuoteDetail.vue"),
      },
      AskCollectDetail: {
        path: "/request/detail/collect",
        name: "request-collect",
        meta: { screenName: "폐기물처리문의" },
        component: () => import("@/views/request/AskCollectDetail.vue"),
      },
    },
  },
};

const unpackRoute = (route) => {
  let routerData = {};
  routerData.path = route.path;
  routerData.component = route.component;
  if (route.meta) {
    routerData.meta = route.meta;
  }
  if (!route.children) {
    routerData.name = route.name;
  } else {
    let children = [];
    Object.entries(route.children).forEach(([key, value]) => {
      children.push(unpackRoute(value));
    });
    routerData.children = children;
  }

  return routerData;
};

const router = createRouter({
  // 스크롤 위치 초기화
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: Object.entries(RoutesData).map(([key, value]) => unpackRoute(value)),
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const navigationStore = useNavigationStore();
  // const redirect = to.query.redirect || from.query.redirect;

  // if (redirect && to.query.redirect !== redirect) {
  //   next({
  //     path: to.path,
  //     query: {
  //       ...to.query,
  //       redirect: redirect,
  //     },
  //   });
  //   return;
  // }

  if (
    to.path === RoutesData.Main.children.Login.path &&
    authStore.isLogined()
  ) {
    throw {
      message: "잘못된 접근 입니다. 이미 로그인 되어있습니다.",
      callback: () => {
        router.replace(RoutesData.Main.children.Main.path);
      },
    };
  }

  if (to.meta.authRequired === true) {
    if (authStore.access == null) {
      throw {
        nextRoute: RoutesData.Main.children.Login.path,
        message: "로그인이 필요합니다",
      };
    }
  } else if (to.meta.authRequired === false) {
    if (authStore.access != null) {
      throw {
        message: "잘못된 접근 입니다. 이미 로그인 되어있습니다.",
        callback: () => {
          router.replace(RoutesData.Main.children.Main.path);
        },
      };
    }
  }

  // 회원가입 완료창 뒤로가기
  if (
    from.path === RoutesData.Auth.children.SignupSuccess.path &&
    to.path !== RoutesData.Main.children.Main.path
  ) {
    throw { nextRoute: "/", message: "잘못된 접근입니다." };
  }

  if (
    from.path === RoutesData.Blw.children.BlwComplete.path &&
    !(
      to.path === RoutesData.Main.children.Main.path ||
      to.path === RoutesData.Main.children.MyHistory.path
    )
  ) {
    throw { nextRoute: "/", message: "잘못된 접근입니다." };
  }

  if (
    from.path === RoutesData.Main.children.Main.path &&
    to.path === RoutesData.Auth.children.SignupSuccess.path
  ) {
    throw { nextRoute: "/", message: "잘못된 접근입니다." };
  }

  // bottom navigation
  if (to.meta.navigationIndex != null) {
    navigationStore.chnageSelectedIndex(to.meta.navigationIndex);
  }

  if (to.meta?.screenName != null) {
    console.log("to.screenName");
    console.log(to.meta.screenName);
    gaScreenEvent(to.meta.screenName);
  }

  next();
});

router.onError((error) => {
  const layoutStore = useLayoutStore();
  if (error.nextRoute) {
    layoutStore.showAlertDialog(error.message, null, null, () => {});
    router.push(error.nextRoute);
  } else {
    layoutStore.showAlertDialog(
      error.message ?? "잘못된 접근입니다.",
      null,
      null,
      () => {
        if (error.callback != null) {
          error.callback();
        }
      }
    );
  }
});

export default router;
