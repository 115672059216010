import "./assets/main.css";
import "./assets/common.css";
import "./assets/service_common.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag";

import App from "./App.vue";
import router from "./router";

//i18
import { createI18n } from "vue-i18n";

// calendar
import VCalendar from "v-calendar";

// custom ui
import AuthBaseLayout from "@/components/layout/AuthBaseLayout.vue";
import RequestBaseLayout from "@/components/layout/RequestBaseLayout.vue";
import InnerNav from "@/components/pannel/InnerNav.vue";

const app = createApp(App);

app.use(createPinia());

// router setting
app.use(router);

app.use(VCalendar);

// i18n setting
const i18n = createI18n({
  locale: "en",
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

app.config.globalProperties.$formatNumber = (value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
  });
  return formatter.format(value);
};

// sentry
Sentry.init({
  app,
  dsn: "https://2d4b697db2f29e2e56874092f2385e8a@o4504468774715392.ingest.us.sentry.io/4508164076404736",
  environment: import.meta.env.MODE,
  integrations: [
    new Sentry.browserTracingIntegration({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration({ router }),
  ],
  trackComponents: true,
  enabled:
    import.meta.env.MODE !== "development" && import.meta.env.MODE !== "locald",
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// custom ui
app.component("RequestBaseLayout", RequestBaseLayout);
app.component("AuthBaseLayout", AuthBaseLayout);
app.component("InnerNav", InnerNav);

// google analytic
if (import.meta.env.VITE_APP_PHASE === "PROD") {
  app.use(
    VueGtag,
    {
      includes: [{ id: "G-LGPZRB0K1N" }, { id: "GTM-56CGM8HW" }],
    },
    router
  );
} else {
  // zguhada 프로젝트의 zguhada-web-test에서 이벤트 확인 가능
  app.use(
    VueGtag,
    {
      includes: [{ id: "G-4ZCYYQZ3WF" }],
    },
    router
  );
}

app.use(i18n);

app.mount("#app");
