import axios from "axios";
import { useAuthStore } from "@/stores/authStore";
import { useLayoutStore } from "@/stores/layoutStore.js";

// import * as Sentry from "@sentry/browser";

const { VITE_API_BASE_URL } = import.meta.env;
const axiosServices = axios.create({
  timeout: 3000,
});
axiosServices.defaults.baseURL = VITE_API_BASE_URL;

axiosServices.interceptors.request.use(
  function (config) {
    console.log("############################");
    console.log(`request url : ${config.url}`);
    console.log(`request method : ${config.method}`);
    if (config.data !== undefined) {
      console.log(`request body : `);
      console.log(config.data);
    }

    if (config.params !== undefined) {
      console.log(`request parameter : `);
      console.log(config.params);
    }

    console.log("############################");
    // 요청 전에 로딩 오버레이 띄우기
    const loadingStore = useLayoutStore();
    loadingStore.showOverlay();

    // 요청 전에 헤더 토큰 설정
    const authStore = useAuthStore();
    if (authStore.access) {
      const token = authStore.access;
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "application/json; charset=utf-8";
    }
    return config;
  },
  function (error) {
    // 에러 나면 로딩 끄기
    const loadingStore = useLayoutStore();
    loadingStore.hideOverlay();
    return Promise.reject(error);
  }
);

// 응답 인터셉터
axiosServices.interceptors.response.use(
  async function (response) {
    console.log("############################");
    console.log(`response url : ${response.config.url}`);
    console.log(`response data : `);
    console.log(response.data);
    console.log("############################");
    // 응답 받으면 로딩 끄기
    const loadingStore = useLayoutStore();
    loadingStore.hideOverlay();

    return response;
  },
  async function (error) {
    if (error.response) {
      const { status, config, data } = error.response;

      if (status === 401) {
        const authStore = useAuthStore();

        return authStore
          .refreshToken()
          .then((refreshResponse) => {
            const config = error.config;
            console.log(
              `refreshResponse ${JSON.stringify(refreshResponse, null, 4)}`
            );

            config.headers[
              "Authorization"
            ] = `Bearer ${refreshResponse.access}`;
            return new Promise((resolve, reject) => {
              axios
                .request(config)
                .then((response) => {
                  resolve(response);
                  const loadingStore = useLayoutStore();
                  loadingStore.hideOverlay();
                })
                .catch((error) => {
                  const loadingStore = useLayoutStore();
                  loadingStore.hideOverlay();
                  reject(error);
                });
            });
          })
          .catch((refreshError) => {
            console.log("get error when refresh");
            console.log(refreshError);
            alert("유저 세션이 만료되었습니다. 로그인 화면으로 돌아갑니다.");

            const authStore = useAuthStore();
            authStore.logout();

            const loadingStore = useLayoutStore();
            loadingStore.hideOverlay();
            return Promise.reject(refreshError);
          });
      } else {
        const loadingStore = useLayoutStore();
        loadingStore.hideOverlay();

        return Promise.reject(error);
      }
    } else {
      alert(
        "알수 없는 오류가 발생했습니다.\n서버와의 연결상태 확인하거나 반복될 경우 관리자에게 문의해주세요."
      );
      const loadingStore = useLayoutStore();
      loadingStore.hideOverlay();
    }
  }
);

export default axiosServices;
