import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";

export const useNavigationStore = defineStore({
  id: "navigation",
  state: () => ({
    selectedIndex: useSessionStorage("selectedIndex", 0),
  }),
  actions: {
    chnageSelectedIndex(index) {
      if (index === this.selectedIndex) {
        return;
      }

      this.selectedIndex = index;
    },
  },
});
