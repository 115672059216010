import { HttpMethods } from "@/utils/constant";
import axiosServices from "@/service/axios/axios";
import axios from "axios";
import authAxiosServices from "@/service/axios/authAxios";
import { useRequestStore } from "@/stores/requestStore";
import { useLayoutStore } from "@/stores/layoutStore";
import { formatDateTime } from "@/utils/dateFormat";

const { VITE_ERP_API_BASE_URL } = import.meta.env;

export const postZguhadaWithdrawal = async () => {
  const url = `zguhada/withdrawal/`;
  const response = await axiosServices({
    method: HttpMethods.post,
    url: url,
    data: {
      withdrawal: true,
    },
  });

  return response.data;
};

// sns 연동 해제
export const getUnlinkSns = async ({ snsName }) => {
  const url = `zguhada/unlink/sns/`;

  try {
    const response = await axiosServices({
      method: HttpMethods.get,
      url: url,
      params: {
        sns: snsName,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLoginSns = async ({ snsType, code, redirectUrl }) => {
  const url = `zguhada/login/sns/`;
  try {
    return (
      await authAxiosServices({
        method: HttpMethods.get,
        url: url,
        params: {
          code: code,
          sns: snsType,
          redirect_uri: redirectUrl,
        },
      })
    ).data;
  } catch (error) {
    throw error;
  }
};

// sns 연동
export const getLinkSns = async ({ snsType, code, redirectUrl }) => {
  const url = `zguhada/link/sns/`;
  try {
    return (
      await axiosServices({
        method: HttpMethods.get,
        url: url,
        params: {
          code: code,
          sns: snsType,
          redirect_uri: redirectUrl,
        },
      })
    ).data;
  } catch (error) {
    throw error;
  }
};

// 프로필 정보 조회
export const postLoginToken = async () => {
  const url = `zguhada/login/`;

  try {
    const response = await axiosServices({
      method: HttpMethods.post,
      url: url,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBanner = async () => {
  const url = `zguhada/contents/`;

  try {
    const response = await axiosServices({
      method: HttpMethods.get,
      url: url,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

/* 철거 견적 문의 */
export const postQuoteInquiry = async (
  name,
  number,
  dueDate,
  address,
  detailAddress,
  area,
  etcInquiries,
  isAgreement
) => {
  const url = `zguhada/inquiry/demolition/`;
  try {
    const response = await axiosServices({
      method: HttpMethods.post,
      url: url,
      data: {
        name: name,
        number: number,
        due_date: dueDate,
        address: address,
        detail_address: detailAddress,
        area: area,
        etc_inquiries: etcInquiries,
        is_agreement: isAgreement,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* 폐기물 수거 문의 */
export const postCollectInquiry = async (
  name,
  number,
  dueDate,
  address,
  detailAddress,
  materialType,
  vehicleType,
  materialSize,
  etcInquiries,
  needForklift,
  registerOlbaro,
  siteImage,
  isAgreement
) => {
  const url = `zguhada/inquiry/collection/`;
  try {
    const response = await axiosServices({
      method: HttpMethods.post,
      url: url,
      data: {
        name: name,
        number: number,
        due_date: dueDate,
        address: address,
        detail_address: detailAddress,
        material_type: materialType,
        vehicle_type: vehicleType,
        material_size: materialSize,
        etc_inquiries: etcInquiries,
        need_forklift: needForklift,
        register_olbaro: registerOlbaro,
        site_image: siteImage,
        is_agreement: isAgreement,
      },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

/* 폐기물 수거 문의 - 이미지 업로드 */

// protected file을 등록하기 위한 api들
const requestProtectedFilePrepare = async (filename) => {
  // protected file을 등록하기 위해 signed url을 요청한다
  let config = {
    method: HttpMethods.get,
    url: `common/file/protected/any/?filename=${filename}`,
  };
  const response = await axiosServices(config);
  return response.data;
};

const completeProtectedFile = async (prepare) => {
  // signed url에 업로드 성공 후 서버에 파일을 등록한다.
  let config = {
    method: HttpMethods.post,
    url: `common/file/protected/any/`,
    data: JSON.stringify(prepare),
    headers: { "Content-Type": "application/json" },
  };
  console.log("config: ", config);
  const response = await axiosServices(config);
  return response.data;
};

const uploadToS3 = async (prepare, fileObject) => {
  // S3에 파일을 업로드한다.
  const response = await axios.put(prepare.upload_url, fileObject, {
    headers: { "Content-Type": fileObject.type },
  });
  if (response.status !== 200) {
    throw new Error("Failed to upload to S3");
  }
  return prepare;
};

export const uploadProtectedFile = async (fileList) => {
  const prepareList = [];
  for (const file of fileList) {
    const prepare = await requestProtectedFilePrepare(file.file.name);
    await uploadToS3(prepare, file.file);
    prepareList.push(prepare);
  }
  return await completeProtectedFile(prepareList);
};

export const getUserServiceHistory = async ({ isCancel }) => {
  const url = `/zguhada/user/service/history/`;

  const response = await axiosServices({
    method: HttpMethods.get,
    url: url,
    params: {
      cancel_yn: isCancel ? "1" : "0",
    },
  });

  return response.data;
};

export const getImageUploadUrl = async (extension) => {
  const url = `zguhada/blw/collectionRequest/image/upload/`;
  const response = await axiosServices({
    method: HttpMethods.get,
    url: url,
    params: {
      extension: extension,
    },
  });

  return response.data;
};

export const putImageUpload = async (url, formData) => {
  try {
    await axios
      .put(url, formData, {
        headers: {
          "Content-Type": "image",
        },
      })
      .then((res) => {
        console.log(`res.data ${res.data}`);
      });
  } catch (error) {
    console.error("업로드 실패:", error);
    throw error;
  }
};

export const postBlwDisposeReportRequest = async ({
  govId,
  name,
  cellphone,
  disposeMemo,
  disposeDate,
  address,
  detailAddress,
  addressType,
  postcodeJson,
  disposeLocationId,
  registeredLocationId,
  paymentMethod,
  paymentId,
  itemList,
  uploadImages,
  channel,
  cash_receipt_type,
  cash_receipt_number,
}) => {
  const url = `/zguhada/blw/${govId}/disposeReport/request/`;

  const convertAddressType = addressType === "ROAD_ADDR" ? "road" : "jibun";

  var convertPostCodeJson = postcodeJson;
  if (typeof postcodeJson == "string") {
    convertPostCodeJson = JSON.parse(postcodeJson);
  }

  const params = {
    name: name,
    cellphone: cellphone,
    address: address,
    detail_address: detailAddress,
    address_type: convertAddressType,
    postcode_json: convertPostCodeJson,
    dispose_location_id: disposeLocationId,
    registered_location_id: registeredLocationId,
    payment_method: paymentMethod,
    payment_id: paymentId,
    item_list: itemList,
    upload_images: uploadImages,
    channel: channel,
    dispose_memo: disposeMemo,
    dispose_date: disposeDate,
    cash_receipt_type: cash_receipt_type,
    cash_receipt_number: cash_receipt_number,
  };

  const response = await axiosServices({
    method: HttpMethods.post,
    url: url,
    data: params,
  });

  return response.data;
};

/* response 예시
{
    "id": 1,
    "name": "성동구청",
    "payment_account_id": "f0614336-a0ae-4d40-9065-4b856bab060f",
    "pg_payment_method_id": "1f5c2484-be9c-4fc9-ab34-16d148ba5f9a",
    "payment_secret_key": "hIoedqHmTc6cToBCsosY7AFcAn0mpRa7SQpZj8tcaZU",
    "bank_account": {
        "id": 1,
        "name": "김원빈계좌",
        "bank": "031",
        "bank_display": "대구은행",
        "account_number": "508140843236",
        "account_holder": "김원빈"
    }
}
*/

export const getBlwServiceGovernment = async (
  guCode,
  addressName,
  addressType,
  x,
  y,
  address,
  roadAddress
) => {
  const url = `/zguhada/blw/serviceGovernment/${guCode}`;

  const params = {
    address_name: addressName,
    addressType: addressType,
    x: x,
    y: y,
    address: address,
    road_address: roadAddress,
  };

  const response = await axiosServices({
    method: HttpMethods.get,
    url: url,
    params: params,
  });

  return response.data;
};

/*
{
    "registered_location_id": 3,
    "dispose_location_id": 1
}
*/
export const getBlwDisposeLocation = async (
  govId,
  x,
  y,
  b_code,
  bonbun,
  bubun
) => {
  const url = `/zguhada/blw/${govId}/disposeLocation/`;
  const response = await axiosServices({
    method: HttpMethods.get,
    url: url,
    params: {
      x: x,
      y: y,
      b_code: b_code,
      bonbun: bonbun,
      bubun: bubun,
    },
  });

  return response.data;
};

/*
"category_list": [
    {
      "id": 635,
      "name": "공기청정기",
      "parent_id": 318,
      "level": 3,
      "zguhada_icon": null
    },
    {
      "id": 315,
      "name": "TV/오디오",
      "parent_id": 312,
      "level": 2,
      "zguhada_icon": null
    },
    {
      "id": 720,
      "name": "아동용 자전거",
      "parent_id": 344,
      "level": 3,
      "zguhada_icon": null
    },
    {
      "id": 700,
      "name": "선반",
      "parent_id": 308,
      "level": 3,
      "zguhada_icon": null
    },
    {
      "id": 734,
      "name": "씨디장",
      "parent_id": 300,
      "level": 3,
      "zguhada_icon": null
    },
*/
export const getProductCategoryList = async (govId) => {
  const url = `/zguhada/blw/${govId}/product/category/list/`;

  const response = await axiosServices({
    method: HttpMethods.get,
    url: url,
  });

  return response.data;
};

/*
{
    "category_list": [
        {
            "id": 303,
            "name": "의자",
            "parent_id": 297,
            "level": 2,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 730,
            "name": "스툴/화장대 의자(미니)",
            "parent_id": 303,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 736,
            "name": "안마의자",
            "parent_id": 319,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 753,
            "name": "범보의자",
            "parent_id": 344,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 784,
            "name": "바퀴 달린 의자",
            "parent_id": 303,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 785,
            "name": "의자",
            "parent_id": 303,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 799,
            "name": "의자(플라스틱)",
            "parent_id": 303,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 810,
            "name": "차량용 의자(앞좌석)",
            "parent_id": 346,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 813,
            "name": "캠핑/낚시 의자",
            "parent_id": 340,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        }
    ]
}
*/
export const getProductCategoryListSearch = async (govId, name) => {
  const url = `/zguhada/blw/${govId}/product/category/list/`;

  let params = {};

  if (name !== "" || name !== null) {
    params = {
      name: name,
    };
  }

  const response = await axiosServices({
    method: HttpMethods.get,
    url: url,
    params: params,
  });

  return response.data;
};

/*
{
    "id": 786,
    "name": "모든 규격",
    "price": 7000
}
*/

export const getProductStandardList = async (govId, categoryId) => {
  const url = `/zguhada/blw/${govId}/product/standard/list/`;

  const response = await axiosServices({
    method: HttpMethods.get,
    url: url,
    params: {
      product_id: categoryId,
    },
  });

  return response.data;
};

/* response 예시
{
    "payment_account_id": "f0614336-a0ae-4d40-9065-4b856bab060f",
    "payment_method_id": "1f5c2484-be9c-4fc9-ab34-16d148ba5f9a",
    "payment_id": "53a5222a-519f-428d-9dfc-d943c5783ff4",
    "payment_type": "cert",
    "next_url": "https://backend-dev.soogobot.com/payment/1f5c2484-be9c-4fc9-ab34-16d148ba5f9a/53a5222a-519f-428d-9dfc-d943c5783ff4/cert/",
    "cert_callback_url": "https://backend-dev.soogobot.com/payment/1f5c2484-be9c-4fc9-ab34-16d148ba5f9a/53a5222a-519f-428d-9dfc-d943c5783ff4/cert/callback/",
    "finish_url": "https://backend.soogobot.com/?uid=53a5222a-519f-428d-9dfc-d943c5783ff4",
    "buyer_name": null,
    "buyer_tel": null,
    "buyer_email": null,
    "request_data": {
        "GoodsName": "대형 생활 폐기물 배출 품목",
        "Amt": 2000,
        "TaxFreeAmt": 0,
        "MID": "nicepay00m",
        "EdiDate": "202401091412",
        "Moid": "53a5222a-519f-428d-9dfc-d943c5783ff4",
        "SignData": "afb34610fea141d03f822bc44f928d199dbfecca6ae4ab782f2623f9679ed127",
        "BuyerName": null,
        "BuyerTel": null,
        "PayMethod": "CARD",
        "BuyerEmail": null,
        "CharSet": "utf-8"
    },
    "cert_response_data": null,
    "approve_response_data": null,
    "status": "pc"
}

*/

export const postPaymentRequest = async (
  payment_method_id,
  amount,
  buyerName,
  buyerTel,
  isMobile
) => {
  const requestStore = useRequestStore();
  const url = `/payment/${payment_method_id}/request/`;

  const payemntAccountId = requestStore.paymentAccountId;
  const paymentAccountSecretKey = requestStore.paymentAccountSecretKey;

  const response = await axiosServices({
    method: HttpMethods.post,
    url: url,
    headers: {
      "X-Chunil-Payment-Id": payemntAccountId,
      "X-Chunil-Payment-Secret": paymentAccountSecretKey,
    },
    data: {
      amount: amount,
      app: "zguhada",
      item_title: "대형 생활 폐기물 배출 품목",
      is_mobile: isMobile,
      tax_free: amount, // 대폐 비과세라 amount 같게.
      buyer_name: buyerName,
      buyer_tel: buyerTel,
      // finish_url: "https://backend.soogobot.com/",
    },
  });

  return response.data;
};

// 배출신고 취소 api
// refundBankId, refundAccountNumber, refundAccountHolder payment_method 가 "MB", 계좌이체 일때
export const postUserServiceCancel = async (
  id,
  serviceType,
  { refundBankId, refundAccountNumber, refundAccountHolder } = {}
) => {
  const url = `/zguhada/user/service/cancel/`;

  let data = {
    id: id,
    service_type: serviceType,
  };

  if (refundBankId != null) {
    data = {
      blw_dispose_report_refund_bank: refundBankId,
      blw_dispose_report_refund_account_number: refundAccountNumber,
      blw_dispose_report_refund_account_holder: refundAccountHolder,
      ...data,
    };
  }

  const response = await axiosServices({
    method: HttpMethods.post,
    url: url,
    data: data,
  });

  return response.data;
};

export const getWasteMapList = async () => {
  const layoutStore = useLayoutStore();
  try {
    layoutStore.showOverlay();
    const url = `${VITE_ERP_API_BASE_URL}wastemap/v1/list/`;
    const response = await axios({
      method: HttpMethods.get,
      url: url,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    console.log("############################");
    console.log(`request api ${url}`);
    console.log(response.data);
    console.log("############################");

    layoutStore.hideOverlay();
    return response.data;
  } catch (error) {
    layoutStore.hideOverlay();
    console.log("############################");
    console.log(error);
    console.log("############################");

    throw error;
  }
};
export const getWasteMapListSearch = async (search) => {
  const layoutStore = useLayoutStore();
  try {
    layoutStore.showOverlay();
    const url = `${VITE_ERP_API_BASE_URL}wastemap/v1/list/`;
    const response = await axios({
      method: HttpMethods.get,
      url: url,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    console.log("############################");
    console.log(`request api ${url} search`);

    const result = response.data.filter((element) => {
      if (element.name) {
        return element.name.includes(search);
      }

      return false;
    });
    console.log(result);

    console.log("############################");

    layoutStore.hideOverlay();
    return result;
  } catch (error) {
    layoutStore.hideOverlay();
    console.log("############################");
    console.log(error);
    console.log("############################");

    throw error;
  }
};

/*
- Path params
  - `사업장_id` : 리스트에서 반환한 사업장의 `id` 값
- Query params
  - `max_date`(optional): 조회 종료일. `yyyy-mm-dd` 형태로 전달. 기본값은 현재일. 이 날짜를 포함한 이전의 데이터만 조회한다.
  - `min_date`(optional): 조회 시작일. `yyyy-mm-dd` 형태로 전달. 기본값은 조회 종료일-30일. 이 날짜를 포함한 이후의 데이터만 조회한다.
*/
export const getWasteMapPriceDetail = async (id) => {
  const layoutStore = useLayoutStore();
  try {
    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 6);

    const url = `${VITE_ERP_API_BASE_URL}wastemap/v1/${id}/price/`;
    layoutStore.showOverlay();
    const response = await axios({
      method: HttpMethods.get,
      url: url,
      params: {
        min_date: formatDateTime(minDate, "YYYY-MM-DD"),
      },
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    console.log("############################");
    console.log(`request api ${url}`);
    console.log(response.data);
    console.log("############################");

    layoutStore.hideOverlay();
    return response.data;
  } catch (error) {
    layoutStore.hideOverlay();

    console.log("############################");
    console.log(error);
    console.log("############################");

    throw error;
  }
};
