export const Severities = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  ERROR: "ERROR",
};

export const HttpMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  patch: "PATCH",
};
