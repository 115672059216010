<script setup>
import { computed } from "vue";
import { useLayoutStore } from "@/stores/layoutStore.js";

const layoutStore = useLayoutStore();

const isWebMobile = computed(() => {
  return layoutStore.isMobileSize;
});
</script>

<template>
  <div v-if="layoutStore.showToast === true" class="toast-box">
    <div
      class="toast mx-[18px] box-shadow flex gap-[8px] items-center"
      :class="isWebMobile ? '' : 'mt-11'">
      <img src="@/assets/icons/icon_radio_ok.svg" class="size-[18px]" /><span
        class="label_6"
        >{{ layoutStore.toast?.detail }}</span
      >
    </div>
  </div>
</template>

<style scoped>
.toast-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 520px;
  /* height: 100vh; */
  z-index: 999;
}
.toast {
  padding: 12px 16px;
  border-radius: 8px;
  background: var(--Blue100, #ebf4ff);
  height: 52px;
}
.box-shadow {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
}
</style>
