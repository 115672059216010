import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";
import { useLocalStorage, useSessionStorage } from "@vueuse/core";
import { RoutesData } from "@/router";
import authAxiosServices from "@/service/axios/authAxios";
import { postPhoneNumberLogin } from "@/service/api/authApi";
import { postLoginToken } from "@/service/api/serviceApi";
import { useLayoutStore } from "@/stores/layoutStore.js";
import { useWebviewStore } from "@/stores/webviewStore";
import Cookies from "js-cookie";
import { ref } from "vue";

const {
  VITE_COOKIE_DOMAIN,
  VITE_COOKIE_SECURE,
  VITE_COOKIE_SAMESITE,
  VITE_API_BASE_URL,
} = import.meta.env;

const serializer = {
  read: (v) => {
    try {
      return v ? JSON.parse(v) : null;
    } catch (error) {
      return null;
    }
  },
  write: (v) => JSON.stringify(v),
};

export const cookieAccessKey = "zguhadaAccess";
export const cookieRefreshKey = "zguhadaRefresh";
export const cookieUserKey = "zguhadaUser";
export const socialState = "zguhadaSocialState";
// 공용으로 사용하는 쿠키
// 구 지구하다와 통신할때는 아래의 쿠키를 사용하며 토큰/refreshsms
export const cookieZguhada = "zguhadaCookie";

// value로 get, set 구분
// function useSessionStorage(key, value = null) {
//   if (value === null) {
//     // GET: 쿠키에서 값 가져오기
//     const cookieValue = Cookies.get(key);

//     try {
//       return cookieValue ? JSON.parse(cookieValue) : null;
//     } catch (error) {
//       console.error("쿠키 데이터 파싱 오류:", error);
//       return null;
//     }
//   } else {
//     // SET: 쿠키에 값 저장하기
//     try {
//       Cookies.set(key, JSON.stringify(value), {
//         domain: VITE_COOKIE_DOMAIN, // 공통 도메인 설정
//         path: "/", // 경로 설정
//         secure: VITE_COOKIE_SECURE, // HTTPS에서만 사용
//       });
//       return value;
//     } catch (error) {
//       console.error("쿠키 저장 오류:", error);
//       return null;
//     }
//   }
// }

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    access: useSessionStorage("access", null, { serializer }),
    refresh: useSessionStorage("refresh", null, { serializer }),
    user: useSessionStorage("user", null, { serializer }),
    signupData: null,
    passwordReset: null,
    socialState: useSessionStorage("socialState", null),
  }),
  actions: {
    async setUser() {
      this.user = await authAxiosServices({
        method: "GET",
        url: `/user/profile/`,
      });
    },
    async phoneNumberLogin({ cellphone, password, redirect }) {
      const layoutStore = useLayoutStore();
      console.log(redirect);
      try {
        const response = await postPhoneNumberLogin(cellphone, password);

        this.access = response.access;
        this.refresh = response.refresh;
        this.user = response.profile;

        if (window.flutter_inappwebview) {
          // TODO: login success 로직 수정
          window.flutter_inappwebview.callHandler("loginSuccess", {
            access: response.access,
            refresh: response.refresh,
            profile: response.profile,
            doRoute: false,
          });

          await this.requestLoginInfo();
          router.push(RoutesData.Main.children.Main.path);
        } else {
          await this.requestLoginInfo();
          router.push(RoutesData.Main.children.Main.path);
        }
      } catch (error) {
        if (error.response?.data.message) {
          layoutStore.showAlertDialog(error.response.data.message);
        } else {
          layoutStore.showAlertDialog(error);
        }
      }
    },
    // setLoginTokenInCookies({ access, refresh, profile }) {
    //   this.access = useSessionStorage(this.cookieAccessKey, access);
    //   this.refresh = useSessionStorage(this.cookieRefreshKey, refresh);
    //   this.user = useSessionStorage(this.cookieUserKey, profile);
    // },
    // removeTokenInCookies() {
    //   Cookies.remove(cookieZguhada, {
    //     domain: VITE_COOKIE_DOMAIN,
    //     path: "/",
    //     secure: VITE_COOKIE_SECURE,
    //   });
    //   this.removeSessionToken();
    // },
    // removeSessionToken() {
    //   Cookies.remove(cookieAccessKey, {
    //     domain: VITE_COOKIE_DOMAIN,
    //     path: "/",
    //     secure: VITE_COOKIE_SECURE,
    //   });
    //   Cookies.remove(cookieRefreshKey, {
    //     domain: VITE_COOKIE_DOMAIN,
    //     path: "/",
    //     secure: VITE_COOKIE_SECURE,
    //   });
    //   Cookies.remove(cookieUserKey, {
    //     domain: VITE_COOKIE_DOMAIN,
    //     path: "/",
    //     secure: VITE_COOKIE_SECURE,
    //   });
    // },
    clearToken() {
      this.access = null;
      this.refresh = null;
      this.user = null;
    },
    async logout() {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler("requestLogout", {
          showAlert: false,
        });
        this.clearToken();
        // this.removeTokenInCookies();
      } else {
        this.clearToken();
        // this.removeTokenInCookies();

        router.push(RoutesData.Main.children.Main.path);
      }
    },
    isLogined() {
      return this.access != null && this.access != "";
    },
    async refreshToken() {
      try {
        const refreshResponse = await axios({
          method: "POST",
          url: `${VITE_API_BASE_URL}account/token/refresh/`,
          data: {
            refresh: this.refresh,
          },
        });

        this.access = refreshResponse.data.access;
        return refreshResponse.data;
      } catch (error) {
        throw error;
      }
    },
    // 약관 데이터 저장
    setSignupTermsData({
      old_14,
      personal_info,
      service_use,
      location_info,
      marketing_push,
      marketing_sms,
    }) {
      this.signupData = {
        ...this.signupData,
        old_14: old_14,
        personal_info: personal_info,
        service_use: service_use,
        location_info: location_info,
        marketing_push: marketing_push,
        marketing_sms: marketing_sms,
      };
    },
    setSnsSignupData({
      social_type,
      social_id,
      social_refresh,
      name,
      phonenumber,
    }) {
      this.signupData = {
        ...this.signupData,
        social_type: social_type,
        social_id: social_id,
        social_refresh: social_refresh,
        name: name,
        phonenumber: phonenumber,
      };
    },
    // 비밀번호 재설정 데이터
    setPasswordResetData({ phone_auth_id }) {
      this.passwordReset = {
        phone_auth_id: phone_auth_id,
      };
    },
    getPasswordResetData() {
      if (this.passwordReset) {
        return this.passwordReset.phone_auth_id;
      } else {
        return null;
      }
    },
    async provideToken(access, refresh) {
      this.access = access; //useSessionStorage(cookieAccessKey, access);
      this.refresh = refresh; //useSessionStorage(cookieRefreshKey, refresh);
      this.user = null;

      if (access != null && access != "") {
        await this.requestLoginInfo();
      }
    },
    async requestLoginInfo() {
      try {
        const response = await postLoginToken();

        this.user = response; //useSessionStorage(cookieUserKey, response);
      } catch (error) {
        throw error;
      }
    },
    setTokenInCookies(access, refresh, redirect) {
      const token = JSON.stringify({
        access: access,
        refresh: refresh,
      });
      Cookies.set(cookieZguhada, token, {
        domain: VITE_COOKIE_DOMAIN,
        path: "/",
        secure: VITE_COOKIE_SECURE,
      });
      this.clearToken();
      router.push(RoutesData.Main.children.Main.path);
    },
    updateUserSnsFieldInCookies(field, newValue) {
      const userData = Cookies.get(cookieUserKey);

      if (!userData) {
        return;
      }

      let parsedData;
      try {
        parsedData = JSON.parse(userData);
      } catch (e) {
        console.error("쿠키데이터 파싱 오류:", e);
        return;
      }

      if (!parsedData.sns) {
        return;
      }

      parsedData.sns[field] = newValue;
      // 업데이트된 데이터를 다시 쿠키에 저장
      Cookies.set(cookieUserKey, JSON.stringify(parsedData), {
        domain: VITE_COOKIE_DOMAIN,
        path: "/",
        secure: VITE_COOKIE_SECURE,
      });
    },
    setSocialState(state) {
      this.socialState = state; //useSessionStorage(socialState, state);
    },
    removeSocialState() {
      this.socialState = null;
      Cookies.remove(socialState, {
        domain: VITE_COOKIE_DOMAIN,
        path: "/",
        secure: VITE_COOKIE_SECURE,
      });
    },
  },
});
