import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import ko from "dayjs/locale/ko";

const DEFAULT_TIME_FORMAT = "YYYY-MM-DD HH:mm";
const FULL_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const HOUR_FORMAT = "HH:mm";

dayjs.locale(ko);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

//https://chunilenergy.atlassian.net/wiki/spaces/platformCenterDev/pages/81461249
export const stringToDate = (dateString) => {
  return dayjs(dateString).tz("Asia/Seoul");
};

// dayjs.tz.setDefault('Asia/Seoul');
export const formatDateTime = (date, format = DEFAULT_TIME_FORMAT) => {
  const parseDate = dayjs(date);

  if (parseDate.isValid()) {
    return parseDate.format(format);
  }

  return null;
};

export const formatDateTimeUtc = (date, format = FULL_TIME_FORMAT) => {
  const parseDate = dayjs.utc(date);

  if (parseDate.isValid()) {
    return parseDate.format(format);
  }

  return null;
};

export const formatDateTimeWithKST = (date, format = FULL_TIME_FORMAT) => {
  const parseDate = dayjs.tz(date, "Asia/Seoul");

  if (parseDate.isValid()) {
    return parseDate.format(format);
  }

  return null;
};

/*
time 데이터는 HH:mm:ss 형식으로 받아야함.
*/
export const formatHourTime = (time, format = HOUR_FORMAT) => {
  const result = dayjs.utc(`1970-01-01T${time}`).format(format);

  return result;
};

// dayjs 포맷이 일반적으로 달라서 Date() 사용
export const today = () => {
  return new Date();
};
