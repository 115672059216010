import * as Sentry from "@sentry/browser";
import { event } from "vue-gtag";

export const copyFallback = (text, successAction) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed"; // 화면 스크롤 방지
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();

  try {
    const successful = document.execCommand("copy");
    if (successful) {
      successAction();
    } else {
      console.error("Fallback: Copying to clipboard failed.");
    }
  } catch (err) {
    console.error("Fallback: Unable to copy", err);
  }

  document.body.removeChild(textarea);
};

/* sentry log 처리 */
export function sendErrorLogToSentry(error) {
  Sentry.captureException(error);
}

export const openServiceAgreement = () => {
  const url =
    "https://sugerbot.notion.site/2023-12-01-960427a58ddb481fa49700317ed02fea";

  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("openBrowser", {
      url: url,
    });
  } else {
    window.open(url);
  }
};

export const openPrivacyAgreement = () => {
  const url =
    "https://sugerbot.notion.site/2023-12-01-868d1c7127c64e2bb5b0bdda2cf0a5da";

  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("openBrowser", {
      url: url,
    });
  } else {
    window.open(url);
  }
};

// km 단위로 거리계산
export function getDistance(lat1, lng1, lat2, lng2) {
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

export const gaEvent = (eventName, eventParams) => {
  event(eventName, eventParams);
};

export const gaScreenEvent = (title) => {
  event("page_event", {
    page_title: title,
  });
};
